<div id="avaliacoes" class="row coments mb-5">
  <div class="col-12 mb-4 d-flex flex-row align-items-center title">
    <span class="text-yellow font-size-regular"> <img src="images/new/star-rating.svg" alt="Média" />{{ this.escola.rate_geral | number : "1.1-1" }}</span>
    <h2 class="text-black font-size-biggest mb-0">
      Avaliações <small class="text-lightgray font-size-smallest">({{ this.reviewCount }})</small>
    </h2>
  </div>

  <!-- ITEMS -->
  <div class="col-12 mb-5">
    <div class="items-review mb-4">
      <div class="item-review">
        <img src="images/new/school/icon-building-estate.svg" alt="Infraestrutura" />
        <h4 class="font-size-smallest text-lightgray">Infraestrutura</h4>
        <p *ngIf="this.escola.rate_infraestrutura > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_infraestrutura | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_infraestrutura <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-security.svg" alt="Segurança" />
        <h4 class="font-size-smallest text-lightgray">Segurança</h4>
        <p *ngIf="this.escola.rate_seguranca > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_seguranca | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_seguranca <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-certificate.svg" alt="Pedagógico" />
        <h4 class="font-size-smallest text-lightgray">Pedagógico</h4>
        <p *ngIf="this.escola.rate_pedagogico > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_pedagogico | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_pedagogico <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-heart-handshake.svg" alt="Acolhimento" />
        <h4 class="font-size-smallest text-lightgray">Acolhimento</h4>
        <p *ngIf="this.escola.rate_acolhimento > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_acolhimento | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_acolhimento <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-chess.svg" alt="Cursos extras" />
        <h4 class="font-size-smallest text-lightgray">Cursos extras</h4>
        <p *ngIf="this.escola.rate_extracurriculares > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_extracurriculares | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_extracurriculares <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-user-star.svg" alt="Diversidade" />
        <h4 class="font-size-smallest text-lightgray">Diversidade</h4>
        <p *ngIf="this.escola.rate_diversidade > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_diversidade | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_diversidade <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
      <div class="item-review">
        <img src="images/new/school/icon-accessible.svg" alt="Inclusão" />
        <h4 class="font-size-smallest text-lightgray">Inclusão</h4>
        <p *ngIf="this.escola.rate_inclusao > 0" class="text-black font-size-featured bold mb-0">{{ this.escola.rate_inclusao | number : "1.1-1" }}</p>
        <p *ngIf="this.escola.rate_inclusao <= 0" class="text-black font-size-featured bold mb-0">-</p>
      </div>
    </div>

    <!-- ACTION -->
    <div class="action d-flex align-items-start justify-content-start flex-column">
      <a href="/modal" data-bs-toggle="modal" (click)="newReview($event)" title="Avalie sua experiência com a escola" class="btn btn-border border-pink text-pink font-size-regular semibold mb-2">Avalie sua experiência com a escola</a>
    </div>
    <!-- END ACTION -->
  </div>
  <!-- END ITEMS -->

  <!-- COMENTS COMPONENT -->
  <div id="depoimentos" class="coments">
    <div class="row mb-4">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" *ngFor="let review of getVisibleReviews()" #reviewContent>
        <div class="coment">
          <div class="coment-header mb-3">
            <div class="avatar" *ngIf="review.avatar_url">
              <figure>
                <img [src]="review.avatar_url" alt="Usuário" />
              </figure>
              <small *ngIf="this.renderPerfil(review)">{{ renderPerfil(review) }}</small>
            </div>
            <div class="avatar avatar-letra" *ngIf="!review.avatar_url">
              <p>{{ review.nome_usuario?.charAt(0) }}</p>
              <small *ngIf="this.renderPerfil(review)">{{ renderPerfil(review) }}</small>
            </div>
            <div class="person-info">
              <h2 class="text-black font-size-big mb-0 pb-0">{{ review.nome_usuario }}</h2>
              <div class="rating d-flex flex-row align-items-center">
                <div class="item" *ngIf="this.reviewShowRate(review)">
                  <img src="images/new/school/icon-share-star.svg" alt="Nota" />
                  <span class="text-black font-size-small">{{ review.rate_geral | number : "1.1-1" : "pt-BR" }}</span>
                </div>
                <div class="item">
                  <img src="images/new/school/icon-share-calendar.svg" alt="Comentários" />
                  <span class="text-black font-size-small">{{ review.data_hora_criada?.toLocaleString("pt-BR", { month: "long", year: "numeric" }) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="coment-content mb-3" [class.active]="review.isExpanded">
            <p *ngIf="!review.aprovado" class="font-size-small text-pink">aguardando aprovação</p>
            <p class="font-size-regular text-lightgray">{{ review.descricao }}</p>
            <div *ngIf="this.reviewHasTag(reviews)" class="tags">
              <ng-container>
                <ng-container>
                  <li *ngFor="let tag of review.tags" class="tag font-size-small">
                    {{ tag }}
                  </li>
                </ng-container>
              </ng-container>
            </div>
            <div class="action" *ngIf="review.showMore">
              <a href="#verMais" (click)="toggleReview(review); $event.preventDefault()" title="mostrar mais" [ngClass]="{ 'btn-view-more': !review.isExpanded, 'btn-view-less': review.isExpanded }" class="btn-view-more text-pink font-size-smallest">{{ review.isExpanded ? "ver menos" : "mostrar mais" }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTION -->
    <div class="col-12" *ngIf="!this.isShowAll">
      <div class="action d-flex align-items-start justify-content-start flex-column" *ngIf="this.reviewCount != 0">
        <a href="#carregarMais" (click)="carregarMais($event)" title="Mostrar todos os 5 depoimentos" class="btn btn-border border-pink text-pink font-size-regular semibold mb-2"> Mostrar todos os {{ this.reviewCount }} depoimentos </a>
      </div>
    </div>
    <!-- END ACTION -->
  </div>
  <!-- END COMENTS COMPONENT -->
</div>
